const active = 'active';
const suspended = 'suspended';
const terminated = 'terminated';
const resigned = 'resigned';
const inactive = 'inactive';
const canceled = 'canceled';
const autoshipFailed = 'autoship_failed';

export const STATUSES = {
	active,
	inactive,
	suspended,
	terminated,
	resigned,
	canceled,
};

export const FINAL_STATUSES_CAN_LOGIN = {
	canceled,
};

export const EDITABLE_STATUSES = [
	active,
	inactive,
];

export const EDITABLE_STATUSES_WITH_SUSPEND_PERMISSION = [
	active,
	inactive,
	suspended,
];

export const NO_EDITABLE_STATUSES_NORMAL_PERMISSIONS = [
	terminated,
	resigned,
	suspended,
	canceled,
];

export const CUSTOMER_EDITABLE_STATUSES = [
	active,
];

export const NO_EDITABLE_STATUSES = [
	terminated,
	resigned,
	canceled,
];

export const CUSTOMERS_REPORT_FILTERS = {
	active,
	suspended,
};

export const DISTRIBUTOR_HIDDEN_STATUSES = {
	terminated,
	resigned,
	canceled,
};

export const DASHBOARD_SUSPENDED_STATUSES = {
	resigned,
	terminated,
	suspended,
	canceled,
};

export const BASIC = { active, suspended };

export const COLORS = [
	{
		statusCode: active,
		color: 'success-alt',
		icon: 'fa fa-user',
	},
	{
		statusCode: inactive,
		color: 'text-muted',
		icon: 'fa fa-user',
	},
	{
		statusCode: suspended,
		color: 'text-danger',
		icon: 'fa fa-user',
	},
	{
		statusCode: terminated,
		color: 'text-danger',
		icon: 'fa fa-user-times',
	},
	{
		statusCode: resigned,
		color: 'text-danger',
		icon: 'fa fa-user-o',
	},
	{
		statusCode: canceled,
		color: 'text-danger',
		icon: 'fa fa-user-slash',
	},
];

export const AUTOSHIP_FAILED = {
	statusCode: autoshipFailed,
	color: 'text-warning',
	icon: 'fas fa-exclamation-triangle',
};

// export const SYMBOLISM = [...COLORS, { separator: true }, AUTOSHIP_FAILED];
export const SYMBOLISM = [...COLORS];

// const canceled = 'canceled';
const complete = 'complete';
const pickup = 'pick_up';
const pending = 'pending';
const processing = 'processing';
const shipped = 'shipped';
const rejected = 'rejected';
const closed = 'closed';
const paymentReview = 'payment_review';
const cryptoCreated = 'crypto_created';
const cryptoPending = 'crypto_pending';
const cryptoExpired = 'crypto_expired';
const externalPending = 'external_pending';
const externalFailed = 'external_failed';
const shopifyComplete = 'shopify_complete';
const demoComplete = 'demo_complete';
export const ORDER_STATUSES = {
	canceled,
	complete,
	pickup,
	pending,
	rejected,
	processing,
	shipped,
	closed,
	paymentReview,
	cryptoCreated,
	cryptoPending,
	cryptoExpired,
	externalPending,
	externalFailed,
	shopifyComplete,
	demoComplete,
};

export const MANUAL_ORDER_STATUSES = {
	complete,
	pickup,
	processing,
	closed,
};

export const ORDER_VERIFICATION_STATUSES_COLORS = {
	corporates: {
		pending: 'warning',
		update_needed: 'secondary',
	},
	users: {
		pending: 'secondary',
		update_needed: 'warning',
		rejected: 'danger',
		valid: 'green',
	},
};

export const STAGES = ['pre_launch', 'soft_launch', 'soft_launch2', 'launch', 'official_launch'];

export default { STATUSES };
